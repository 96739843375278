import { ObjectType } from '@/app/constants/typedef';
import { Community, Home, StoreIcon, Writing, MyChannel, MyChannelActive, HomeActive, CommunityActive, WritingActive, StoreIconActive } from '@/public/icon';

export const navIcons = {
  home: Home,
  community: Community,
  store: StoreIcon,
  writing: Writing,
  mychannel: MyChannel,
  homeActive: HomeActive,
  communityActive: CommunityActive,
  storeActive: StoreIconActive,
  writingActive: WritingActive,
  mychannelActive: MyChannelActive,
} as ObjectType;

'use client';
import React from 'react';
import { BoxProps, SxProps, Typography, TypographyProps, useTheme } from '@mui/material';
import CenteredBox from '../CenterBox';

interface BaseCardNoticeGrayProps extends TypographyProps {
    content?: string;
    sx?: SxProps;
}

const BaseCardNoticeGray: React.FC<BaseCardNoticeGrayProps> = ({ content, sx = {}, ...other }) => {
    const theme = useTheme();
    const noticeContent = `
    안녕하세요, 조이쌤입니다. <br /> 
    설 연휴로 인해 배송 일정이 조정되어 사전 안내해 드립니다.
    <br /> <br />
    <strong>배송중단 기간</strong><br />
    2024년 02월 09일(금) ~ 2024년 02월 12일(월)
    <br /> <br />
    <strong>세부 배송일정</strong><br />
    <ul>
      <li>2024년 02월 06일(화) 오후 12시 이전 결제: 02월 09일(금) 이전 출고</li>
      <li>2024년 02월 06일(화) 오후 12시 이전 결제: 02월 09일(금) 이전 출고</li>
    </ul>
    <br />
    연휴 이후 안전하고 빠르게 배송 예정이오니, 참고하여 이용 부탁드립니다. 궁금하신 부분은 언제든지 고객센터로 접수해 주세요.
    <br /> <br />
    즐겁고 행복한 명절 보내시고 새해 복 많이 받으세요. 감사합니다.
  `;
    return (
        <CenteredBox
            sx={{
                padding: '30px 10px',
                flexDirection: 'column',
                borderRadius: '16px',
                background: theme.palette.background.paper,
                lineHeight: '100%',
                ...sx,
            }}
        >
            <Typography
                variant="h2_12Regular"
                textAlign={'center'}
                sx={{
                    '& ul': {
                        paddingInlineStart: '0px',
                        listStylePosition: 'inside',
                        margin: 0,
                    },
                    '& li': {
                        paddingInlineStart: '0px',
                        margin: 0,
                        '&::marker': {
                            fontSize: '0.6em',
                        },
                    },
                    '& span': {
                        paddingInlineStart: '0px',
                    },
                }}
                dangerouslySetInnerHTML={{ __html: content ?? noticeContent }}
                {...other}
            />
        </CenteredBox>
    );
};

export default BaseCardNoticeGray;

// import React, { useState, useEffect, useRef } from 'react';
// import { Typography, TypographyProps } from '@mui/material';

// interface ResizableParagraphProps extends TypographyProps {
//     text: string;
//     lineNumber?: number | string;
//     // startIcon?: React.ReactNode;
//     endIcon?: React.ReactNode;
//     onTruncated?: (truncated: boolean) => void;
// }

// const EllipsisTypoGraphyMultiLine: React.FC<ResizableParagraphProps> = ({ text, lineNumber = 1, endIcon, onTruncated, sx = {}, ...other }) => {
//     const [isTruncated, setIsTruncated] = useState(false);
//     const paragraphRef = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//         onTruncated?.(isTruncated);
//     }, [isTruncated]);

//     useEffect(() => {
//         const checkIfTruncated = () => {
//             if (paragraphRef.current) {
//                 setIsTruncated(paragraphRef.current.scrollHeight > paragraphRef.current.clientHeight);
//             }
//         };

//         const resizeObserver = new ResizeObserver(() => checkIfTruncated());
//         if (paragraphRef.current) {
//             resizeObserver.observe(paragraphRef.current);
//         }

//         checkIfTruncated();

//         return () => {
//             if (paragraphRef.current) {
//                 resizeObserver.unobserve(paragraphRef.current);
//             }
//         };
//     }, []);

//     const paragraphStyle = {
//         '--line-clamp': lineNumber,
//         display: '-webkit-box',
//         WebkitLineClamp: 'var(--line-clamp)',
//         WebkitBoxOrient: 'vertical' as const,
//         // width: 'clamp(300px, 50%, 900px)', // if needed will open to handle responsive for desktop browsers
//         overflow: 'hidden' as const,
//         position: 'relative' as const,
//         '&::before': {
//             content: '""',
//             float: 'right' as const,
//             height: 'calc((var(--line-clamp) - 1) * 1lh)',
//         },
//         '& .icon:first-of-type': {
//             clear: 'both' as const,
//             float: 'right' as const,
//         },
//         '&:not(.truncated) .icon:first-of-type': {
//             display: 'none' as const,
//         },
//     };

//     return (
//         <Typography
//             ref={paragraphRef}
//             component="div"
//             sx={{ ...paragraphStyle, ...sx }}
//             className={isTruncated ? 'truncated' : ''}
//             {...other}
//         >
//             {endIcon && (
//                 <span
//                     className="icon"
//                     style={{ clear: 'both', float: 'right' }}
//                 >
//                     {endIcon}
//                 </span>
//             )}
//             {text}
//             {endIcon ? (
//                 <span
//                     className="icon"
//                     style={{
//                         marginLeft: 2,
//                         // , display: 'inline-block', margin: 0, padding: 0, lineHeight: 1
//                     }}
//                 >
//                     {endIcon}
//                 </span>
//             ) : null}
//         </Typography>
//     );
// };

// export default EllipsisTypoGraphyMultiLine;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import deepmerge from 'deepmerge';

interface ResizableParagraphProps extends TypographyProps {
    text: string;
    lineNumber?: number | string;
    // startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    besideEndIcon?: React.ReactNode;
    onTruncated?: (truncated: boolean) => void;
}

const EllipsisTypoGraphyMultiLine: React.FC<ResizableParagraphProps> = ({ text, lineNumber = 1, endIcon, besideEndIcon, onTruncated, sx = {}, ...other }) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const paragraphRef = useRef<HTMLDivElement>(null);
    const [heightParagraph, setHeightParagraph] = useState<number>(0);
    const [heightEndIcon, setHeightEnIcon] = useState<number>(0);
    const iconRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        onTruncated?.(isTruncated);
    }, [isTruncated]);

    useEffect(() => {
        const checkIfTruncated = () => {
            if (paragraphRef.current) {
                const current = paragraphRef.current;
                setHeightParagraph(paragraphRef.current.clientHeight);
                setIsTruncated(paragraphRef.current.scrollHeight > paragraphRef.current.clientHeight);
            }
        };

        const resizeObserver = new ResizeObserver(() => checkIfTruncated());
        if (paragraphRef.current) {
            resizeObserver.observe(paragraphRef.current);
        }

        checkIfTruncated();

        return () => {
            if (paragraphRef.current) {
                resizeObserver.unobserve(paragraphRef.current);
            }
        };
    }, []);

    const paragraphStyle = React.useMemo(() => {
        return {
            '--line-clamp': lineNumber,
            display: '-webkit-box',
            WebkitLineClamp: 'var(--line-clamp)',
            WebkitBoxOrient: 'vertical' as const,
            overflow: 'hidden' as const,
            position: 'relative' as const,
            '&::before': {
                content: '""',
                float: 'right' as const,
                height: 'calc((var(--line-clamp) - 1) * 1lh)',
                // height: heightParagraph - heightEndIcon,
            },
            '& .icon:first-of-type': {
                clear: 'both' as const,
                float: 'right' as const,
            },
            '&:not(.truncated) .icon:first-of-type': {
                display: 'none' as const,
            },
        };
    }, [heightParagraph, isTruncated, heightEndIcon]);

    useEffect(() => {
        if (iconRef.current) {
            const iconHeight = iconRef.current.getBoundingClientRect().height;
            setHeightEnIcon(iconHeight);
        }
    }, [endIcon, heightParagraph, isTruncated]);
    return (
        <Typography
            ref={paragraphRef}
            component="div"
            // sx={{ ...paragraphStyle, , ...sx }}
            sx={deepmerge<SxProps<Theme>>(
                {
                    ...paragraphStyle,
                    color: 'grayscale.gray07',
                },
                sx
            )}
            className={isTruncated ? 'truncated' : ''}
            {...other}
        >
            {endIcon && (
                <span
                    ref={iconRef}
                    className="icon"
                    style={{ clear: 'both', float: 'right' }}
                >
                    {endIcon}
                </span>
            )}
            {text}
            {besideEndIcon && (
                <span
                    // className="icon"
                    style={{ lineHeight: 'normal' }}
                >
                    {besideEndIcon}
                </span>
            )}
            {endIcon && (
                <span
                    className="icon"
                    style={{ marginLeft: 4 }}
                >
                    {endIcon}
                </span>
            )}
        </Typography>
    );
};

export default EllipsisTypoGraphyMultiLine;

'use client';

import React, { ReactNode, useEffect } from 'react';
import { Dialog, DialogActions, Button, Typography, Box, DialogProps } from '@mui/material';
import CenteredBox from '../CenterBox';
import BaseCardNoticeGray from '../BaseCardNoticeGray';
import EllipsisTypography from '../EllipsisTypography';
import { TransitionZoom } from '@/app/components/MUI/Transition';

interface CustomModalProps extends DialogProps {
    open: boolean;
    icon?: React.ReactNode;
    title: string;
    lineHeight?: '100%' | 'normal';
    description: string;
    showCancel?: boolean;
    showAccept?: boolean;
    onCancel: () => void;
    onAccept: () => void;
    cancelText?: string;
    acceptText?: string;
    showBaseCardNotice?: boolean;
    baseCardContent?: string | ReactNode;
}

const BaseModal: React.FC<CustomModalProps> = ({
    open,
    icon,
    title,
    description,
    showCancel,
    showAccept,
    onCancel,
    onAccept,
    cancelText = 'Cancel',
    acceptText = 'Accept',
    showBaseCardNotice = false,
    baseCardContent,
    lineHeight,
    ...other
}) => {
    const buttonCount = [showCancel, showAccept].filter(Boolean).length;
    useEffect(() => {
        const mainLayout = document.body;
        if (open) {
            // const mainLayout = document.getElementById('main-layout-joy');
            if (mainLayout) {
                setTimeout(() => {
                    mainLayout.style.overflow = 'hidden';
                }, 1);
            }
        } else {
            if (mainLayout) {
                mainLayout.style.overflow = 'auto';
            }
        }
        return () => {
            const mainLayout = document.body;
            if (mainLayout) {
                mainLayout.style.overflow = '';
            }
        };
    }, [open]);

    return (
        <Dialog
            aria-modal
            aria-hidden={false}
            open={open}
            disableScrollLock={true}
            TransitionComponent={TransitionZoom}
            sx={{ '& .MuiPaper-root': { bgcolor: '', maxWidth: '335px', width: '100%' } }}
            PaperProps={{
                sx: {
                    minWidth: '335px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'background.default',
                    borderRadius: '20px',
                    padding: '40px 20px',
                    gap: '30px',
                },
            }}
            {...other}
        >
            <CenteredBox sx={{ flexDirection: 'column', maxWidth: '100%', mx: 'auto', lineHeight: lineHeight ?? '100%', gap: 5 }}>
                <>{icon && <Box sx={{ textAlign: 'center' }}>{icon}</Box>}</>
                <Box sx={{ textAlign: 'center' }}>
                    {title && (
                        <Typography
                            variant="h2_14Medium"
                            textAlign="center"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    )}
                    {description && (
                        <CenteredBox sx={{ pt: '10px' }}>
                            <Typography
                                variant="h2_12Medium"
                                textAlign="center"
                                dangerouslySetInnerHTML={{ __html: description }}
                            >
                                {/* {description} */}
                            </Typography>
                        </CenteredBox>
                    )}
                </Box>
            </CenteredBox>
            {showBaseCardNotice && (
                <CenteredBox sx={{ flexDirection: 'column', maxWidth: '100%', mx: 'auto' }}>
                    {typeof baseCardContent == 'string' && (
                        <Box sx={{ width: '100%' }}>
                            <BaseCardNoticeGray
                                content={baseCardContent}
                                sx={{ padding: 5 }}
                            />
                        </Box>
                    )}
                    {typeof baseCardContent == 'object' && baseCardContent}
                </CenteredBox>
            )}
            <DialogActions sx={{ p: '0px 4px 0 4px', width: '100%' }}>
                <CenteredBox sx={{ justifyContent: 'center', width: '100%', gap: 3 }}>
                    {showCancel && (
                        <Button
                            sx={{
                                backgroundColor: 'background.default',
                                color: 'grayscale.gray07',
                                width: '137.5px',
                                // padding: '9px 14px',
                                borderRadius: '20px',
                                textTransform: 'unset',
                                minHeight: 36,
                                border: '1px solid var(--text-gray-07000, #000)',
                                '&:hover': {
                                    backgroundColor: 'background.default',
                                    opacity: 0.9,
                                },
                            }}
                            onClick={onCancel}
                        >
                            <EllipsisTypography
                                lineNumber={1}
                                variant="h2_12Medium"
                                lineHeight="100%"
                            >
                                {cancelText}
                            </EllipsisTypography>
                        </Button>
                    )}
                    {showAccept && (
                        <Button
                            sx={{
                                backgroundColor: 'secondary.main',
                                color: 'grayscale.gray07',
                                width: '137.5px',
                                // padding: '9px 14px',
                                borderRadius: '20px',
                                textTransform: 'unset',
                                minHeight: 36,
                                border: '1px solid var(--text-gray-07000, #000)',
                                '&:hover': {
                                    backgroundColor: 'secondary.main',
                                    opacity: 0.9,
                                },
                            }}
                            onClick={onAccept}
                        >
                            <EllipsisTypography
                                lineNumber={1}
                                variant="h2_12Medium"
                                lineHeight="100%"
                            >
                                {acceptText}
                            </EllipsisTypography>
                        </Button>
                    )}
                </CenteredBox>
            </DialogActions>
        </Dialog>
    );
};

export default BaseModal;

import { ObjectType } from '@/app/constants/typedef';

class AuthUser {
    id: string | number | undefined;
    username: string | null = null;
    accessToken?: string;
    refreshToken?: string;
    isRegistered?: boolean;
    firebaseToken?: string;
    expired_at?: number | string

    constructor(obj?: ObjectType) {
        if (obj) {
            this.init(obj);
        }
    }

    private init(obj: ObjectType) {
        this.id = obj.id ?? undefined;
        this.username = obj.username ?? null;
        this.accessToken = obj.accessToken ?? undefined;
        this.refreshToken = obj.refreshToken ?? undefined;
        this.isRegistered = obj.hasOwnProperty('isRegistered') ? obj.isRegistered : undefined;
        this.firebaseToken = obj.firebaseToken ?? undefined;
        this.expired_at = obj.expired_at ?? undefined;
    }

    updateRegistrationStatus(is_registered: boolean) {
        this.isRegistered = is_registered;
    }
}

export default AuthUser; 
import { NavItemConfig } from '../constants/nav';
import { paths } from '@/app/constants/paths';

export const navItems = [
  { key: 'home', label: '홈', title: '홈', href: paths.home, icon: 'home' },
  { key: 'community', label: '커뮤니티', title: '커뮤니티', href: paths.community.challenges, icon: 'community' },
  { key: 'writing', label: '글쓰기', title: '글쓰기', href: paths.writing, icon: 'writing' },
  { key: 'store', label: '스토어', title: '스토어', href: paths.store, icon: 'store' },
  { key: 'mychannel', label: '마이채널', title: '마이채널', href: paths.mychannel.settings, icon: 'mychannel' },
] satisfies NavItemConfig[];

'use client';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { logger } from '@/app/lib/logger/index';
import { getCookie, setCookie } from 'cookies-next';
import { decrypt, encrypt } from '../helpers/cryptoMethods';
import UserModel from '../models/user/UserModel';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AuthUser from '../models/auth/AuthUser';
import { EditProfileRequest } from '../models/user/IUser';
import moment from 'moment';
export interface UserContextValue {
    auth: AuthUser | null;
    user: UserModel | null;
    error: string | null;
    isLoading: boolean;
    updateDataFromCookiesForProvider?: () => Promise<void>;
    checkPermissions: () => Promise<any>;
    setUser: (data: EditProfileRequest) => void;
}

export interface UserProviderProps {
    children: ReactNode;
}

export const UserContext = createContext<UserContextValue | undefined>(undefined);

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
    const [state, setState] = useState<{ user: UserModel | null; auth: AuthUser | null; error: string | null; isLoading: boolean }>({
        user: null,
        auth: null,
        error: null,
        isLoading: true,
    });

    const checkPermissions = async (): Promise<any> => {
        let hasPermission = false;
        if (!state.auth?.id) {
            hasPermission = false;
        } else if (state.auth?.id && !state.auth?.isRegistered) {
            hasPermission = false;
        } else if (state.auth?.id && state.auth?.isRegistered) {
            hasPermission = true;
        }
        return hasPermission;
    };

    const updateDataFromCookiesForProvider = useCallback(async (): Promise<void> => {
        try {
            setState((prev) => ({ ...prev, isLoading: true }));
            const cookieUser = getCookie('user');
            const _accessToken = getCookie('_access');
            let auth = null;
            let user = null;
            if (cookieUser) {
                const decryptedUser = decrypt(cookieUser, process.env.SECRET_KEY);
                const userData = decryptedUser ? JSON.parse(decryptedUser) : null;
                user = userData
                    ? new UserModel({
                          id: userData.id,
                          nickname: userData.nickname || '',
                          email: userData.email || undefined,
                          contacts: userData.contacts ?? [],
                          is_staff: userData.isStaff ?? null,
                          is_influencer: userData.isInfluencer ?? null,
                          is_expert: userData.isExpert ?? null,
                          is_google_logged_in: userData.isGoogleLoggedIn ?? false,
                          is_apple_logged_in: userData.isAppleLoggedIn ?? false,
                          avatar: userData.avatarUrl ?? null,
                          phone_number: userData.phoneNumber ?? null,
                          updated_at: userData.updatedAt ? moment(userData.updatedAt) : moment(),
                          created_at: userData.createdAt ? moment(userData.createdAt) : moment(),
                      })
                    : null;
                if (_accessToken) {
                    const authObject = {
                        accessToken: decrypt(_accessToken, process.env.SECRET_KEY),
                        id: userData.id,
                        username: user?.nickname,
                        isRegistered: true,
                        //add more if needed or any required
                    };
                    auth = userData ? new AuthUser(authObject) : null;
                }
            }
            setState((prev) => ({ ...prev, user, auth, isLoading: false }));
        } catch (error) {
            logger.error('Error fetching user data from cookies:', error);
            setState((prev) => ({ ...prev, isLoading: false, error: 'Failed to load user data', user: null, auth: null }));
        }
    }, []);

    const setUser = ({ nickname, avatar, ...other }: EditProfileRequest) => {
        setState((prev: any) => {
            const updatedUser = prev.user ? { ...prev.user, nickname, avatarUrl: avatar, ...other } : null;

            if (updatedUser) {
                const encryptedUser = encrypt(JSON.stringify(updatedUser), process.env.SECRET_KEY);
                setCookie('user', encryptedUser);
            }

            return {
                ...prev,
                user: updatedUser,
            };
        });
    };

    useEffect(() => {
        updateDataFromCookiesForProvider?.();
    }, [updateDataFromCookiesForProvider]);

    if (state.isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
            <UserContext.Provider value={{ ...state, updateDataFromCookiesForProvider, checkPermissions, setUser }}>{children}</UserContext.Provider>
    );
};

export const UserConsumer = UserContext.Consumer;

import type { UserContextValue } from '@/app/contexts/user-context';
import { UserContext } from '@/app/contexts/user-context';
import { useContext } from 'react';

export function useUser(): UserContextValue {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

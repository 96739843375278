export const paths = {
    home: '/',
    community: {
        challenges: '/community/challenges',
    },
    auth: {
        signIn: '/login',
        signUp: '/sign-up',
        // registration: '/registration',
        signOut: '/sign-out',
    },
    store: '/store',
    writing: '/writing',
    mychannel: {
        default: '/mychannel',
        settings: '/mychannel/settings',
        faq: '/mychannel/faq',
        announcements: '/mychannel/announcements',
    },
} as const;

'use client';
import React from 'react';
import Paper from '@mui/material/Paper';
import { usePathname } from 'next/navigation';
import RenderNavItems from './Items';
import { navItems } from '@/app/layout/nav-items';
import { MAX_SCREEN_WIDTH } from '@/app/constants/layoutConfig';
import { useTheme } from '@mui/material';

const Navigation = () => {
    const pathname = usePathname();
    const theme= useTheme()
    return (
        <Paper sx={{ ...styles.Paper, background: theme.palette.background.default, alignItems: 'center' }} elevation={5}>
            <RenderNavItems pathname={pathname} items={navItems} />
        </Paper>
    );
};

const styles: { Paper: React.CSSProperties } = {
    Paper: {
        boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.04)',
        borderRadius: '0px',
        position: 'fixed',
        bottom: 0,
        margin: '0 auto',
        width: '100%',
        maxWidth: MAX_SCREEN_WIDTH,
        left: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        right: 0,
        height: '70px',
        zIndex: 99,
        padding: '0 16px',
    },
};

export default Navigation;

'use client';
import { NavItemConfig } from '@/app/constants/nav';
import BottomNavigation from '@mui/material/BottomNavigation';
import Link from 'next/link';
import { BottomNavigationAction, Typography } from '@mui/material';
import React, { useState } from 'react';
import { isNavItemActive } from '@/app/lib/logger/is-nav-item-active';
import { navIcons } from '../NavIcon';
import theme from '@/app/theme';
import BaseModal from '@/app/base-ui/BaseModalUI';
import { useUser } from '@/app/hooks/useUser';
import { useRouter } from 'next/navigation';

interface NavItemProps extends Omit<NavItemConfig, 'items'> {
    pathname: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}
const storeLink = 'https://smartstore.naver.com/joysam';
export default function RenderNavItems({ items = [], pathname }: { items?: NavItemConfig[]; pathname: string }): React.JSX.Element {
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', description: '' });
    const { user } = useUser();
    const router = useRouter();
    const handleNavItemClick = (key: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (['writing'].includes(key)) {
            event.preventDefault();
            if (!user?.id) {
                router.push('/login');
                return;
            } else {
                router.push('/writing', { scroll: false });
            }
        }
        if (['store'].includes(key)) {
            event.preventDefault();
            window.open(storeLink);
        }
        // if (['mychannel'].includes(key) && !user?.id) {
        //     event.preventDefault();
        //     router.push('/login');
        // }
    };

    const children = items?.map((item: NavItemConfig) => {
        const { key, label, ...rest } = item;
        return (
            <NavItem
                key={key}
                pathname={pathname}
                label={label}
                onClick={(event: any) => handleNavItemClick(key, event)}
                {...rest}
            />
        );
    });

    return (
        <>
            <BottomNavigation
                showLabels
                sx={{ width: '100%', backgroundColor: theme.palette.background.default }}
            >
                {children}
            </BottomNavigation>
            {openModal && (
                <BaseModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title={modalContent.title}
                    description={modalContent.description}
                    showAccept={true}
                    showCancel={false}
                    onCancel={() => setOpenModal(false)}
                    onAccept={() => setOpenModal(false)}
                    acceptText="확인"
                />
            )}
        </>
    );
}

function NavItem({ disabled, external, href, icon, matcher, pathname, title, key, label, onClick, ...rest }: NavItemProps): React.JSX.Element {
    const active = isNavItemActive({ disabled, external, href, matcher, pathname });
    const Icon = icon ? (active ? navIcons[`${icon}Active`] : navIcons[icon]) : null;
    return (
        <BottomNavigationAction
            showLabel
            component={Link}
            href={href}
            label={
                <Typography
                    variant="h2_10Medium"
                    color="grayscale.gray07"
                    sx={{
                        mt: 1,
                        lineHeight: '100%',
                    }}
                >
                    {label}
                </Typography>
            }
            value={key}
            icon={Icon ? <Icon /> : null}
            onClick={onClick}
            sx={{
                ...(active && { bgcolor: '' }),
                px: 0,
                minWidth: 'calc(360px / 8)',
                '& .MuiBottomNavigationAction-root': {
                    padding: 0,
                },
                '& .MuiBottomNavigationAction-label': {
                    marginTop: '5px',
                    lineHeight: '100%',
                },
            }}
        />
    );
}
